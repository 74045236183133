import { useRecoilState } from "recoil";
import MenuItem from "./MenuItem";
import Profile from "./Profile";
import { Menu, Navbar, VersionInfo } from "./styles";
import userState from "../../states/userState";

function MenuBar() {
  const [user] = useRecoilState(userState);

  const guestsLabel =
    user?.residential?.labelType !== "commercial"
      ? "Visitantes"
      : "Pessoas externas";
  const residentsLabel =
    user?.residential?.labelType !== "commercial"
      ? "Moradores"
      : "Pessoas internas";

  return (
    <Menu>
      <Profile />
      <Navbar>
        <ul>
          <MenuItem title="Interfone" href="/interfone" />
          <MenuItem title="Mensagens" href="/mensagens" />
          <MenuItem title="Liberar visitas" href="/liberar/hoje" />
          <MenuItem title="Abrir" href="/abrir" />
          <MenuItem title="Administração" href="/administracao" />
          <MenuItem title={residentsLabel} href="/moradores" />
          <MenuItem title={guestsLabel} href="/visitantes" />
          {/* <MenuItem title="Câmeras" href="/cameras" /> */}
          <VersionInfo>
            GUUG
            <br />
            v0.1.20221021
          </VersionInfo>
        </ul>
      </Navbar>
    </Menu>
  );
}

export default MenuBar;
