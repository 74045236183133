import { MessagePayload } from "firebase/messaging";
import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import * as VoxImplant from "voximplant-websdk";
import "./App.css";
import phoneRingingMp3 from "./assets/audios/phone-ringing.mp3";
import CallModal from "./components/CallModal";
import "./firebase";
import useLogout from "./hooks/useLogout";
import {
  AccessNotificationData,
  AppNotification,
  CallNotificationData,
  EmergencyNotificationData,
  MessageNotificationData,
  NotificationData,
  OpenDoorNotificationData,
  PhotoValidationNotificationData,
  VehicleNotificationData,
} from "./interfaces/AppNotification";
import { User } from "./interfaces/User";
import Administration from "./pages/Administration";
import GuestsHistory from "./pages/AllowGuests/GuestsHistory";
import ScheduledGuests from "./pages/AllowGuests/ScheduledGuests";
import TodayGuests from "./pages/AllowGuests/TodayGuests";
import Intercom from "./pages/Intercom";
import Login from "./pages/Login";
import Messages from "./pages/Messages";
import OpenPorts from "./pages/OpenPorts";
import RegisterGuest from "./pages/RegisterPeople/RegisterGuest";
import RegisterResident from "./pages/RegisterPeople/RegisterResident";
import { api } from "./services/api";
import callIdsState, { callIdsSelector } from "./states/callIdsState";
import callingUserState from "./states/callingUserState";
import { currentChatMessagesState } from "./states/currentChatMessagesState";
import notificationsState from "./states/notificationsState";
import photoValidationState from "./states/photoValidationState";
import receivingCallState from "./states/receivingCallState";
import selectedUserToChatState, {
  SelectedUserToChat,
} from "./states/selectedUserToChatState";
import userState from "./states/userState";
import { encrypt } from "./utils/crypto";
import { imagePreviewState } from "./states/imagePreviewState";
import { IoMdCloseCircleOutline } from "react-icons/io";

type RingingData = {
  interval: NodeJS.Timer | null;
  playing: boolean;
};

function App() {
  const voxImplantClient = VoxImplant.getInstance();

  const navigate = useNavigate();
  const location = useLocation();

  const ringingDataRef = useRef<RingingData>({
    interval: null,
    playing: false,
  });
  const phoneRingingRef = useRef<HTMLAudioElement>(null);

  const logout = useLogout();

  const [user, setUser] = useRecoilState(userState);
  const setSelectedUserToChat = useSetRecoilState(selectedUserToChatState);
  //const [currentCallId, setCurrentCallId] = useRecoilState(currentCallIdState);

  const [callingUser, setCallingUser] = useRecoilState(callingUserState);

  const setNotifications = useSetRecoilState(notificationsState);

  const setCurrentChatMessages = useSetRecoilState(currentChatMessagesState);

  const setPhotoValidation = useSetRecoilState(photoValidationState);

  const [receivingCall, setReceivingCall] = useRecoilState(receivingCallState);

  const callIds = useRecoilValue(callIdsSelector);

  const [imagePreview, setImagePreview] = useRecoilState(imagePreviewState);

  const setCallIds = useRecoilCallback(
    ({ set }) =>
      (value) => {
        set(callIdsState as any, value);
      },
    []
  );
  const getCallIds = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const state = await snapshot.getPromise(callIdsState);

        return state;
      },
    []
  );

  const loginPersist = useCallback(async () => {
    try {
      const res = await api.post("/user/loginPersistent", {
        id: user.id,
        uuid: user.uuid,
      });

      const { data } = res;
      const { token } = data;

      if (token) {
        localStorage.setItem("guugwebporter-jwt-token", encrypt(token));
        delete data.token;
      }

      return data;
    } catch (e) {
      console.log(e);
    }
  }, [user]);

  const makeLoginAgain = useCallback(async () => {
    const jsonUser = localStorage.getItem("guugWebPorter.user");

    if (jsonUser) {
      const { phone } = JSON.parse(jsonUser);

      const voxImplantUrl = process.env.REACT_APP_VOXIMPLANT_URL;

      const { alreadyInitialized } = voxImplantClient;

      if (!alreadyInitialized) await voxImplantClient.init({});

      await voxImplantClient.connect();

      try {
        await voxImplantClient.login(`${phone}${voxImplantUrl}`, "123456");
      } catch (e: any) {
        console.log(e);
      }
    }
  }, [voxImplantClient]);

  const getCallById = (id: string) => {
    const call = VoxImplant.getInstance().getCall(id);

    return call;
  };

  const getCallingUser = useCallback(async (callId: string): Promise<User> => {
    const { number } = getCallById(callId).settings;
    const phoneNumber = number.replace(/(.+:)(\d+)(@.+)/, "$2");
    const res = await api.get(`user/searchByPhone/${phoneNumber}`);

    return res.data;
  }, []);

  const removeCallId = (callIds: string[], current: string) => {
    const callIdsAux = [...callIds];

    const newCallIds = callIdsAux.filter((callId) => callId !== current);

    return newCallIds;
  };

  const cleanCall = useCallback(
    async (id: string) => {
      const callIds = await getCallIds();

      if (callIds.length > 1) {
        const isFirstCallId = callIds[0] === id;

        const newCallIds = removeCallId(callIds, id);

        setCallIds(newCallIds);

        if (isFirstCallId) {
          const newCallId = newCallIds[0];

          const user = await getCallingUser(newCallId);

          setCallingUser(user);

          setReceivingCall(true);
        }
      } else {
        setCallingUser(null);

        setCallIds([]);
        setReceivingCall(false);
      }
    },
    [getCallIds, getCallingUser, setCallIds, setCallingUser, setReceivingCall]
  );

  const addIncomingCallEvent = useCallback(async () => {
    return VoxImplant.getInstance().on(
      VoxImplant.Events.IncomingCall,
      async (e) => {
        try {
          //let interval: NodeJS.Timer;

          const callIds = await getCallIds();

          if (callIds.length > 0) setCallIds([...callIds, e.call.id()]);
          else {
            /* interval = setInterval(() => phoneRingingRef.current?.play(), 500);
            phoneRingingRef.current?.play(); */

            setReceivingCall(true);

            setCallIds([e.call.id()]);

            const user = await getCallingUser(e.call.id());

            setCallingUser(user);
          }

          e.call.addEventListener(
            VoxImplant.CallEvents.Connected,
            (e: any) => {}
          );
          e.call.addEventListener(
            VoxImplant.CallEvents.Disconnected,
            async (e: any) => await cleanCall(e.call.id())
          );
          e.call.addEventListener(
            VoxImplant.CallEvents.Failed,
            async (e: any) => await cleanCall(e.call.id())
          );
        } catch (e) {
          console.log(e);
        }
      }
    );
  }, [
    cleanCall,
    getCallIds,
    getCallingUser,
    setCallIds,
    setCallingUser,
    setReceivingCall,
  ]);

  const initializeAndConnectVoxImplant = useCallback(async () => {
    await makeLoginAgain();

    await addIncomingCallEvent();
  }, [addIncomingCallEvent, makeLoginAgain]);

  const injectNewNotification = useCallback(
    (
      content: AppNotification,
      utcDate: string,
      type:
        | "OpenDoorNotification"
        | "AccessNotification"
        | "MessageNotification"
        | "CallNotification"
        | "EmergencyNotification"
        | "VehicleNotification"
        | "Default"
    ) => {
      const time = moment(utcDate).format("HH:mm");
      const date = moment(utcDate).format("DD/MM/YYYY");
      const createdAt = moment().format();
      const jsonUser = localStorage.getItem("guugWebPorter.user");
      let localStorageUser: User | undefined;

      if (jsonUser) localStorageUser = JSON.parse(jsonUser);

      let contentMessageType: MessageNotificationData =
        content.data as MessageNotificationData;

      const newNotification: AppNotification =
        type === "MessageNotification"
          ? contentMessageType.permission.includes("Porter") &&
            contentMessageType.userId_to !== String(localStorageUser?.id)
            ? {
                ...content,
                data: {
                  ...content.data,
                  user_id: contentMessageType.userId_to,
                  imageUUID: contentMessageType.imageUUID_to,
                  name: contentMessageType.name_to,
                  apartment: contentMessageType.apartment_to,
                  tower: contentMessageType.tower_to,
                  condominium: contentMessageType.condominium_to,
                  residential: contentMessageType.residential_to,
                  permission: contentMessageType.permission_to,
                  user_phone: contentMessageType.user_phone_to,
                } as MessageNotificationData,
                time,
                date,
                type,
                createdAt,
              }
            : {
                ...content,
                data: content.data as MessageNotificationData,
                time,
                date,
                type,
                createdAt,
              }
          : type === "OpenDoorNotification"
          ? {
              ...content,
              data: content.data as OpenDoorNotificationData,
              time,
              date,
              type,
              createdAt,
            }
          : type === "CallNotification"
          ? {
              ...content,
              data: content.data as CallNotificationData,
              time,
              date,
              type,
              createdAt,
            }
          : type === "AccessNotification"
          ? {
              ...content,
              data: content.data as AccessNotificationData,
              time,
              date,
              type,
              createdAt,
            }
          : type === "EmergencyNotification"
          ? {
              ...content,
              data: content.data as EmergencyNotificationData,
              time,
              date,
              type,
              createdAt,
            }
          : type === "VehicleNotification"
          ? {
              ...content,
              data: content.data as VehicleNotificationData,
              time,
              date,
              type,
              createdAt,
            }
          : {
              ...content,
              data: content.data as NotificationData,
              time,
              date,
              type,
              createdAt,
            };

      // Notification sended by the same user
      if (
        newNotification.type === "MessageNotification" &&
        newNotification.data.user_id === String(localStorageUser?.id)
      )
        return;

      setNotifications((notifications) => {
        const notificationsAux = [...notifications];

        if (newNotification.type === "MessageNotification") {
          const sameUserMessageIndex = notifications.findIndex(
            (notification) => {
              if (
                notification.type === "MessageNotification" &&
                notification.data.userId_to ===
                  newNotification.data.userId_to &&
                notification.data.user_id === newNotification.data.user_id
              )
                return true;
              return false;
            }
          );

          if (sameUserMessageIndex !== -1)
            notificationsAux.splice(sameUserMessageIndex, 1);
        }

        if (notificationsAux.length > 499) notificationsAux.shift();

        if (localStorageUser) {
          localStorage.setItem(
            `guugWebPorter.notifications.user${localStorageUser.id}`,
            JSON.stringify([...notificationsAux, newNotification])
          );
        }

        if (notificationsAux.length > 0) {
          return [...notificationsAux, newNotification];
        } else {
          return [newNotification];
        }
      });
    },
    [setNotifications]
  );

  const getMainPath = useCallback((pathname: string) => {
    const locationPath = pathname;
    const lastSlashIndex = locationPath.indexOf("/", 1);

    const mainPath =
      lastSlashIndex > -1
        ? locationPath.substring(0, lastSlashIndex)
        : locationPath;

    return mainPath;
  }, []);

  const handleLocationChange = useCallback(() => {
    const mainPath = getMainPath(location.pathname);

    if (mainPath !== "/mensagens") {
      setCurrentChatMessages([]);
      setSelectedUserToChat({} as SelectedUserToChat);
    }
  }, [
    getMainPath,
    location.pathname,
    setCurrentChatMessages,
    setSelectedUserToChat,
  ]);

  const handleRedirect = useCallback(() => {
    if (!user.id) {
      const storedUser = localStorage.getItem("guugWebPorter.user");
      const userData: User | null = storedUser ? JSON.parse(storedUser) : null;

      if (userData && userData.id) {
        setUser(userData);

        if (location.pathname === "/")
          navigate("/interfone", { replace: true });
      } else navigate("/", { replace: true });
    }
  }, [user.id, navigate, setUser, location.pathname]);

  const handleOnMessageEvent: (payload: MessagePayload) => void = useCallback(
    (payload) => {
      if (payload) {
        const content = payload as unknown as AppNotification;

        const isMessageNotification = Object.keys(content.data).some(
          (key) => key === "userId_to"
        );
        const isOpenDoorNotification = Object.keys(content.data).some(
          (key) => key === "userThatRelease"
        );
        const isPhotoValidationNotification = Object.keys(content.data).some(
          (key) => key === "imageResult"
        );

        if (isPhotoValidationNotification) {
          const data = content.data as PhotoValidationNotificationData;
          const isValid = data.imageResult === "true" ? "true" : "false";

          setPhotoValidation({
            isValid,
          });
        }
      }
    },
    [setPhotoValidation]
  );

  useEffect(() => {
    handleRedirect();
    handleLocationChange();
  }, [handleLocationChange, handleRedirect]);

  useEffect(() => {
    initializeAndConnectVoxImplant();
  }, []);

  useEffect(() => {
    if (user.id && user.uuid) {
      loginPersist().then((user) => {
        if (user) {
          setUser(user);

          localStorage.setItem("guugWebPorter.user", JSON.stringify(user));
        } else logout();
      });

      navigator.serviceWorker.addEventListener("message", (event) => {
        const { data } = event.data;

        const isPhotoValidationNotification = Object.keys(data).some(
          (key) => key === "imageResult"
        );

        if (isPhotoValidationNotification) {
          const imageData = data as PhotoValidationNotificationData;
          const isValid = imageData.imageResult === "true" ? "true" : "false";

          setPhotoValidation({
            isValid,
          });
        }

        const isMessageNotification = Object.keys(data).some(
          (key) => key === "userId_to"
        );
        const isOpenDoorNotification = Object.keys(data).some(
          (key) => key === "userThatRelease"
        );
        const isCallNotification = Object.keys(data).some(
          (key) => key === "call"
        );
        const isAccessNotification = Object.entries(data).some(
          ([key, value]) => key === "userType" && value !== "car"
        );
        const isEmergencyNotification = Object.keys(data).some(
          (key) => key === "isEmergency"
        );
        const isVehicleNotification = Object.entries(data).some(
          ([key, value]) => key === "userType" && value === "car"
        );

        const type = isMessageNotification
          ? "MessageNotification"
          : isOpenDoorNotification
          ? "OpenDoorNotification"
          : isCallNotification
          ? "CallNotification"
          : isAccessNotification
          ? "AccessNotification"
          : isEmergencyNotification
          ? "EmergencyNotification"
          : isVehicleNotification
          ? "VehicleNotification"
          : "Default";

        if (
          isMessageNotification ||
          isOpenDoorNotification ||
          isCallNotification ||
          isAccessNotification ||
          isEmergencyNotification ||
          isVehicleNotification
        )
          injectNewNotification(event.data, moment().format(), type);
      });

      const jsonUser = localStorage.getItem("guugWebPorter.user");
      if (jsonUser) {
        const localStorageUser = JSON.parse(jsonUser);
        const { id } = localStorageUser;

        if (user.id) {
          const notificationsJson = localStorage.getItem(
            `guugWebPorter.notifications.user${user.id}`
          );

          if (notificationsJson) {
            const notifications = JSON.parse(notificationsJson);
            setNotifications(notifications);
          }
        } else if (id) {
          setUser(localStorageUser);

          const notificationsJson = localStorage.getItem(
            `guugWebPorter.notifications.user${id}`
          );

          if (notificationsJson) {
            const notifications = JSON.parse(notificationsJson);
            setNotifications(notifications);
          }
        }

        setInterval(() => {
          if (id) {
            const clientState = VoxImplant.getInstance().getClientState();

            if (clientState !== VoxImplant.ClientState.LOGGED_IN)
              makeLoginAgain();
          }
        }, 60000 * 5);
      }
    }
  }, [user.id, user.uuid]);

  useEffect(() => {
    if (
      callIds.length > 0 &&
      receivingCall &&
      !ringingDataRef.current.playing
    ) {
      const interval = setInterval(() => phoneRingingRef.current?.play(), 500);
      phoneRingingRef.current?.play();

      ringingDataRef.current.interval = interval;
      ringingDataRef.current.playing = true;
    } else if (!receivingCall || callIds.length === 0) {
      if (ringingDataRef.current.interval)
        clearInterval(ringingDataRef.current.interval);

      phoneRingingRef.current?.pause();

      ringingDataRef.current.interval = null;
      ringingDataRef.current.playing = false;
    }
  }, [receivingCall, callIds]);

  return (
    <div
      onClick={() => {
        if (imagePreview) setImagePreview(null);
      }}
    >
      {imagePreview && (
        <div
          className="image-preview-container"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            style={{
              background: "none",
              border: "none",
              position: "absolute",
              right: 10,
              top: 10,
            }}
            onClick={() => setImagePreview(null)}
          >
            <IoMdCloseCircleOutline color="#ccc" size={50} />
          </button>
          <img src={imagePreview} alt="" />
        </div>
      )}
      <audio ref={phoneRingingRef} src={phoneRingingMp3} />
      {callingUser && (
        <CallModal
          receivingCall={receivingCall}
          hangUp={async () => {
            const id = callIds[0];

            if (id) {
              const call = getCallById(id);

              call.hangup();
            }
          }}
          volumeUp={() => {}}
          mute={(mute: boolean) => {
            const id = callIds[0];

            if (id) {
              const call = getCallById(id);

              if (mute) call.muteMicrophone();
              else call.unmuteMicrophone();
            }
          }}
          callingUser={callingUser}
          answer={() => {
            const id = callIds[0];

            if (id) {
              const call = getCallById(id);

              call.answer();
            }

            setReceivingCall(false);
          }}
          decline={async () => {
            const id = callIds[0];

            if (id) {
              const call = getCallById(id);

              call.decline();
            }
          }}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route index element={<Login />} />
        <Route path="/interfone" element={<Intercom />} />
        <Route path="/mensagens" element={<Messages />} />
        <Route path="/liberar">
          <Route path="hoje" element={<TodayGuests />} />
          <Route path="agendados" element={<ScheduledGuests />} />
          <Route path="historico" element={<GuestsHistory />} />
        </Route>
        <Route path="/abrir" element={<OpenPorts />} />
        <Route path="/administracao" element={<Administration />} />
        <Route path="/moradores" element={<RegisterResident />} />
        <Route path="/visitantes" element={<RegisterGuest />} />
        {/* <Route path="/cameras" element={<Cameras />} /> */}
      </Routes>
    </div>
  );
}

export default App;
