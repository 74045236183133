import { ChangeEventHandler } from "react";
import { Container } from "./styles";

interface ControlledInputProps {
  label?: string;
  id?: string;
  type?: string;
  min?: string | number;
  max?: string | number;
  size?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
  list?: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

export default function ControlledInput({
  label = "",
  id = "",
  type = "text",
  min = "",
  max = "",
  size = "md",
  placeholder = "",
  className = "",
  disabled,
  error,
  list = "",
  value,
  onChange,
}: ControlledInputProps) {
  const errorClass = error && error.length > 0 ? "error" : "";

  return (
    <Container className={className}>
      <label htmlFor={id}>{label}</label>
      <input
        list={list}
        className={errorClass}
        id={id}
        type={type}
        min={min}
        max={max}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
      {error && <small>{error}</small>}
    </Container>
  );
}
